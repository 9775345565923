
import { defineComponent, ref, Ref } from 'vue';
import paymentInfo,
{ manageInfo as paymentManageInfo } from '@/components/view/common/payment/index';
import { order, orderType } from '@/data';

export default defineComponent({
    components: {
        paymentInfo,
        paymentManageInfo
    },
    props: {
        id: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const orderDetail: Ref<orderType.OrderInfo> = ref({
            ID: '',
            AccountID: '',
            CreateTime: '',
            Months: '',
            OrderNumber: '',
            Status: '',
            StatusEnum: '',
            Type: '',
            TypeEnum: '',
            Payer: '',
            PayerType: '',
            BeforeOncePrice: 0,
            CouponCount: 0,
            CouponNumber: null,
            FinalPrice: 0,
            OriginalPrice: 0,
            TotalPrice: 0,
            PayPlatform: '',
            PaypalEmail: '',
            PaypalOrder: '',
            Childern: [],
            ProjectType: '',
            NextTime: ''
        });

        const showManageInfo = ref(false);
        order.getInfoSingleOrder(props.id, (data: orderType.OrderInfo) => {
            orderDetail.value = data;
            showManageInfo.value = true;
        });

        return {
            orderDetail,
            showManageInfo
        };
    }
});
